import { Utils } from './utils';
import { ModalDispatcher } from './components/modalDispatcher';
import { Swiper, Navigation, Scrollbar, Zoom } from 'swiper/dist/js/swiper.esm.js';
import anime from 'animejs/lib/anime.es.js';
import SimpleBar from 'simplebar';


document.addEventListener('DOMContentLoaded', () => {

	document.body.classList.add('is-contact');

	setTimeout(function () {
		document.body.classList.add('is-loaded');
	}, 2000);

	setTimeout(function () {
		document.body.classList.add('is-ready');
	}, 2300);



	var elems = document.querySelectorAll('[data-anim]');

	function intersectionHandler(entries) {
	  [].forEach.call(entries, function(entry) {
		 if (entry.isIntersecting) {
			entry.target.classList.add('is-visible')
		 }
		 else {
			entry.target.classList.remove('is-visible')
		 }

	  })
	}

	var observer = new IntersectionObserver(intersectionHandler);

	[].forEach.call(elems, function(entry) {
	  observer.observe(entry);
	});


	var geojson = {
		"type": "FeatureCollection",
		"features": [
		{
			"type": "Feature",
			"properties": {
				"iconSize": [75, 75]
			},
			"geometry": {
				"type": "Point",
				"coordinates": [131.9109912, 43.1258667]
			}
		},
		]
	}

	mapboxgl.accessToken = 'pk.eyJ1IjoiYW5kcmV3a29yIiwiYSI6ImNrMWJoZGkwbDBvNjczaXBmNTQ1ZDFtYzAifQ.g2tkySUupQTV4tR7jxKZXA';
	var map = new mapboxgl.Map({
		container: 'map_container',
		style: 'mapbox://styles/andrewkor/ck4803vgj2a0o1do8zrf1xg4z',
		zoom: 16.5,
		center: [131.9109912, 43.1258667],
	});

	geojson.features.forEach(function(marker) {

		var el = document.createElement('div');
		el.className = 'marker';

		new mapboxgl.Marker(el)
		.setLngLat(marker.geometry.coordinates)
		.addTo(map);
	});


	map.on('wheel', event =>  {

		if (event.originalEvent.ctrlKey) {
			return;
		}

		if (event.originalEvent.metaKey) {
			return;
		}

		if (event.originalEvent.altKey) {
			return;
		}

		event.preventDefault();
	});

	var inputsForm = document.querySelectorAll('.wrapper_input__input');

	[].forEach.call(inputsForm, function(input) {
		input.addEventListener('input', function() {

			setTimeout(() => {	
				if(input.value) {
					input.classList.add('is-filled');
				} else {
					input.classList.remove('is-filled');
				}
			})

		});
	});

	var simpleBar = document.querySelectorAll('.simple_bar');
	[].forEach.call(simpleBar, function(bar) {
		new SimpleBar(bar);
	});

	var phoneElem = document.querySelectorAll('[name="phone"]'),
	phoneMasks = [];
	[].forEach.call(phoneElem, function (el, i) {
		phoneMasks[i] = new IMask (el, {
			mask: '+{7} (0',
			placeholderChar: ''
		});
		el.addEventListener('focus', function () {
			phoneMasks[i].updateOptions({lazy: false})
		})
		phoneMasks[i].on('accept', function () {
			var mask = (phoneMasks[i]._unmaskedValue === '7') ? '+{7} (0' : '+{7} (000) 000-00-00',
			placeholderChar = (phoneMasks[i]._unmaskedValue === '7') ?  '' : '_';
			phoneMasks[i].updateOptions({mask: mask, placeholderChar: placeholderChar})
		})
		el.addEventListener('blur', function () {
			if (phoneMasks[i]._unmaskedValue.length < 11) {
				el.value = '';
				phoneMasks[i].updateOptions({lazy: true})
				phoneMasks[i].value = '';  
			}

		})

	});

	ModalDispatcher.init();

})





